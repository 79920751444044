// .bg-login {
//     &:before {
//         background-image: url("../images/bg-login.webp");
//         content: "";
//         height: 100%;
//         left: 0;
//         opacity: 0.95;
//         position: absolute;
//         top: 0;
//         width: 100%;
//         z-index: -1;
//         background-repeat: no-repeat;
//         background-size: cover;
//     }
// }
.bg-gradient-primary {
    background-image: linear-gradient(to right, #ff0000, #ff3400, #ff4c00, #ff5f00, #ff6f00);
}

.navbar-brand img {
	max-width: 100%;
    max-height: 40px;
	height: auto;
}

.login-page {
    position: relative;
    background-image: linear-gradient(to right, #ff0000, #ff3400, #ff4c00, #ff5f00, #ff6f00);
    height: 100vh;
}

.offcanvas-list {
    ul li {
        font-weight: 550;
    }
    ul li a {
        text-decoration: none;
    }

    ul li:hover {
        background: var(--bs-primary);
    }
    ul li:hover a {
        color: var(--bs-white);
    }
}

.page-header {
    position: relative;
    background-position: 50%;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 0;
    padding: 30px 0 60px;
    background-image: url("../images/bg-login.webp");

    &::before {
        background: #dd222282;
    }

    &::after,
    &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }
    .page-info {
        z-index: 2;
        position: relative;
        color: var(--bs-white);
        text-align: center;
    }
}

.main {
    margin-top: -3rem;
    position: relative;
    z-index: 3;
}

.my-shadow {
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

svg:not(:root) {
    overflow: hidden;
}

.ringtone {
    @extend .rounded;
    @extend .my-shadow;
    @extend .clearfix;
    position: relative;

    .img-icon {
        margin-right: 0.5rem;
        height: 60px;
        img {
        position: absolute;
            border-top-left-radius: .25rem!important;
            border-bottom-left-radius: .25rem!important;
            width: 60px;
            height: 60px;
        }
    }
    .ring-info {
        line-height: 1.6;

        &::after {
            display: block;
            clear: both;
            content: "";
        }
        @extend .mx-1;
        @extend .text-decoration-none;
        @extend .d-block;

        .title {
            color: var(--bs-dark);
            @extend .fs-5;
            @extend .mb-0;
            @extend .text-truncate;
            @extend .pt-1;

            &:hover {
                color: var(--bs-primary);
            }
        }
        
        .subtitle {
            color:  #4A5255;
            @extend .mb-0;
            @extend .small;
            @extend .text-truncate;
        }
    }
    .des {
        @extend .mx-3;
        p {
            @extend .text-muted;
            @extend .mb-0;
            @extend .small;
            @extend .text-truncate;
        }
        &::after {
            display: block;
            clear: both;
            content: "";
        }
    }
    .buttons {
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .pb-2;
    }
}


.ringtone.active {
    @extend .bg-gradient-primary;
    
    .title {
        color: var(--bs-white);
        &:hover {
            color: var(--bs-gray-300);
        }
    }
    .subtitle {
    color: var(--bs-gray-300)
    }
}


.ring-actions {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .justify-content-lg-end;

    .my-button {
        width: 45px;
        height: 45px;
        line-height: 45px;
    }
    .icon {
        height: 20px;
        width: 20px;
    }
}

.ring-downloads {
    @extend .d-grid;
    @extend .gap-2;
    @extend .mt-2;
    @extend .w-100;
    @extend .mx-auto;

    .btn {
        border-radius: 30px;
    }

    .btn:hover {
        background-color: rgb(255, 60, 0);
        border-color: rgb(255, 60, 0);
    }
}

.ring-tags {
    @extend .d-flex;
    @extend .list-unstyled;
    @extend .flex-wrap;
    @extend .justify-content-center;

    li {
        margin: 8px 5px;

        a {
            &::before {
                content: "#";
            }
        }
    }

    li .btn {
        border-radius: 20px;
        border: 1px dashed #ccc;
    }

    li .btn:hover {
        background: var(--bs-warning);
    }
}

// .ring-category {
//     @extend .text-center;
//     @extend .my-3;

//     .btn {
//         box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//         border: 1px dashed #ccc;
//         border-radius: 30px;
//     }
// }

.bg-badge-ringtone {
    background-color: #00000047;
}

.searchform {
    width: calc(60% - 60px);
}

.shares button {
    border-style: none;
    background: transparent;
}
.shares {
    .icon {
        height: 60px;
        width: 60px;
        text-align: center;
        margin: 10px;
        border-radius: 50%;
        display: inline-block;
        text-decoration: none;
        position: relative;
        color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    }

    .fb {
        background: #0165e1;
    }
    .whatsapp {
        background: #25d366;
    }
    .pinterest {
        background: #e60023;
    }

    .tw {
        background: #55acee;
    }

    .icon:hover {
        opacity: 0.8;
    }
}

.preview_thumb.album,.preview_thumb.playlist,.catImg img {
    width: 200px;
    height: 200px;
    margin-bottom: .5rem;
}

.img-icon .hover-play {
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}
.img-icon:hover .hover-play {
    opacity: 1;
}

.mini-player {
    display: flex;
    height: 50px;

/* First column with fixed width */
.mini-thumb {
    display: flex;
    align-self: center;
    margin-left: 5px;
    img {
       height:  40px;
       width: 40px;
       border-radius: 50%;
    }
}

.rotate {
    animation: rotation 8s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

/* Second column taking up 70% of the container's width */
.mini-info {
    flex: 100;
    margin: 0 10px;
    width: 0;
    pointer-events: none;
    user-select: none;

    .title {
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
    }

    .sub-title {
        font-size: 12px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        color:var(--bs-gray-300)
    }
}

/* Third column taking up 30% of the container's width */
.mini-buttons {
    margin-right: 5px;
    align-self: center;

.mini-button-group{
    user-select: none;

    span.ic {
        cursor: pointer;
        width: 26px;
        height: 26px;
        margin-right: 3px;
        margin-left: 3px;
      }

      .ic.loading {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }

      .ic svg {
        width: 30px;
        height: 30px;
        fill: currentColor;
        transition: fill 0.3s ease;
      }

      .ic:hover svg {
            stroke-width: 0;
            stroke: var(--bs-dark);
            fill: var(--bs-dark);
      }
    }
}

  
}