// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$link-decoration: none;

$offcanvas-horizontal-width:300px;
$primary:  #ff4c00 ;

$badge-font-size: 0.85rem;
$breadcrumb-padding-x: 0.5rem;
$breadcrumb-padding-y: 0.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: #dedede;
$breadcrumb-border-radius: 0.25rem;
$breadcrumb-active-color: #3E4347;