#secondary-navbar {
    margin-top: 55px;
    position: relative;
    z-index: 3;

  .bottom-nav {
    @extend .bg-gradient-primary;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    display: flex;
    overflow-x: auto;
    color: var(--bs-white);

    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;
      color: var(--bs-white);
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      transition: background-color 0.1s ease-in-out;
    }
    .nav-link:hover {
      color: var(--bs-white);;
    }

    .active {
      color: var(--bs-gray-300)
    }
    i {
      font-size: 18px;
    }
  }
}