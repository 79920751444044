//Category List styles

.ct {
    @extend .rounded;
    @extend .d-flex;
    @extend .align-items-center;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

    .ct-icon {
        @extend .p-2;
        @extend .flex-shrink-0;
        img {
            width: 60px;
            height: 60px;
            @extend .card-img;
        }
    }
    .ct-body {
        @extend .overflow-hidden;
        @extend .flex-grow-1;
        .title {
            @extend .text-truncate;
            @extend .fs-5;
            @extend .mb-0;
        }
    }
    

}


//Category Grid Styles
.category {
    
.thumb-wrapper:hover .thumb {
    transform: scale(1.3);
    transition: transform 0.3s;
    @extend .rounded;
}

    @extend .shadow-sm;
    color: var(--bs-dark);
    img {
        width: 100%;
    }
    .thumb-wrapper {
        overflow: hidden;
        @extend .position-relative;
    }

    .thumb {
        @extend .ratio;
        @extend .ratio-1x1;
    }
    .bottom-right {
        @extend .position-absolute;
        @extend .bottom-0;
        @extend .end-0;
        @extend .m-1;
        @extend .badge;
        font-weight: 400;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .right-top {
        @extend .position-absolute;
        @extend .top-0;
        @extend .end-0;
        @extend .m-1;
        @extend .badge;
        font-weight: 400;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .category-body2 {
        position: absolute;
        left: 0;
        background-color: #000000bf;
        font-size: 10px;
        color: #fff;
        padding: 0 10px;
        bottom: 0;
        width: 100%;
        text-transform: uppercase;
        font-weight: 400;
        @extend .text-center;
        letter-spacing: 1px;
        margin: 0;
        max-height: 20px;
        line-height: 20px;
        overflow: hidden;
    }

    .category-body {
        @extend .position-absolute;
        @extend .bottom-0;
        @extend .start-0;
        @extend .rounded-bottom;
        @extend .w-100;
        color: rgb(255, 255, 255);
        background-color: var(--bs-primary);
        h4,
        .title {
            @extend .text-truncate;
            @extend .text-uppercase;
            @extend .p-1;
            margin: 2px;
            font-size: 0.85rem;
            font-weight: 400;
            line-height: 1;
            @extend .text-center;
        }
    }
}





.album {
    .thumb-wrapper:hover .thumb {
        transform: scale(1.3);
        transition: transform 0.3s;
        @extend .rounded;
    }

    
    @extend .shadow-sm;
    color: var(--bs-dark);
    img {
        width: 100%;
    }
    .thumb-wrapper {
        overflow: hidden;
        @extend .position-relative;
    }

    .thumb {
        @extend .ratio;
        @extend .ratio-1x1;
    }
    .bottom-right {
        @extend .position-absolute;
        @extend .bottom-0;
        @extend .end-0;
        @extend .m-1;
        @extend .badge;
        font-weight: 400;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .right-top {
        @extend .position-absolute;
        @extend .top-0;
        @extend .end-0;
        @extend .m-1;
        @extend .badge;
        font-weight: 400;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .album-body {

        .album-bg {
                display: block;
                background: rgba(0, 0, 0, 0.5);
                margin: 7%;
                border-radius: 4px;
                padding: 5px;
        }
        @extend .position-absolute;
        @extend .start-0;
        @extend .bottom-0;
        @extend .rounded-bottom;
        @extend .w-100;
        color: var(--bs-white);
        h4,
        .title {
            @extend .text-truncate;
            @extend .text-uppercase;
            margin: 0;
            font-size: 0.85rem;
            font-weight: 400;
            line-height: 1;
            @extend .text-center;
        }
        .subtitle {
            margin: 0;
            @extend .text-truncate;
            font-size: 0.75rem;
            font-weight: 400;
            @extend .text-center;
        }
    }
}

.artist {
    text-decoration: none !important;
    color: var(--bs-dark);
    border: none;
    background-color: transparent;

    img {
        border-radius: 50%;
        border: 2px solid var(--bs-primary);
    }  
    .thumb {
        @extend .ratio;
        @extend .ratio-1x1;
    }
    .artist-body {
        @extend .p-2;
        color: var(--bs-dark);
        h4,
        .title {
            @extend .text-truncate;
            @extend .text-uppercase;
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            @extend .text-center;
        }
    }
}