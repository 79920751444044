.my-button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    margin: 0 10px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    color: #fff;
}

.my-button.fav:not(.light) {
    color: var(--bs-white);
    background-image: linear-gradient(to right, #ff0000, #ff3400, #ff4c00, #ff5f00, #ff6f00);
}

.my-button.fav.light {
    background-color: #eee;
}

.my-button.light {
    color: var(--bs-primary);
    background-color: #eee;
}
.my-button.light:hover:not(.fav) {
    background-color: var(--bs-primary);
    color: #eee;
}
.button.dark {
    background-color: #555;
}
.button.dark:hover:not(.fav) {
    background-color: var(--bs-primary);
}

.player {
    position: relative;
    float: left;
    display: block;
    margin-top: 4px;
    height: 48px;
    width: 48px;
    --rotation: 45deg;

    .icon {
        color: var(--bs-white);
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
        width: 26px;
        height: 26px;
    }
    .icon.spin {
        margin-left: -5px;
    }
    .icon.icon-play {
        margin-left: 0;
    }
    .icon.icon-stop {
        margin-top: 15px;
margin-left: -6px;
width: 24px;
height: 24px;
    }
}

.player.loader::after {
    content: "";
    opacity: 1;
    position: absolute;
    border: 2px solid var(--bs-white);
    border-radius: 50%;
    border-top: 2px solid var(--bs-danger);
    width: 40px;
    height: 40px;
    left: 0;
    bottom: 0;
    -webkit-transform: rotate(var(--rotation));
    transform: rotate(var(--rotation));
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
}

.icon {
    display: inline-block;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    height: 14px;
    width: 14px;
}

.ringtone-play {
    
    width: 200px;
    height: 200px;
    img {
    width: 100%;
    height: 100%;
}

    border: none;
    border-radius: 20px;
    @extend .mb-2;

    .my-button.player {
        float: none;
        width: 96px;
        height: 96px;
        margin: auto;
    }

    .my-button.player.loader::after {
        width: 96px;
        height: 96px;
        border-width: 4px;
        border-color: var(--bs-light);
        border-top-color: var(--bs-gray-600);
    }

    .my-button.player .icon {
        margin-top: 25px;
        margin-left: 1px;
        height: 46px;
        width: 46px;
        color: #fff;
    }

    .my-button.player .icon.icon-play {
        margin-left: 8px;
    }
}
